.container {
  @apply flex flex-col justify-center bg-green-25 rounded;
  @apply px-4 py-4 mt-6 mb-4 md:m-0 md:mr-0 md:mx-8;
  @apply md:mr-6;

  @apply border-l-0 !important;

  &.small .holder {
    @apply flex-row items-center;
  }

  &.small .heading {
    @apply flex-1 pr-2;
  }

  &.small .button {
    @apply flex items-center justify-center p-0 m-0 h-13 w-13;
  }
}

.holder {
  @apply flex flex-col flex-wrap items-start;
}

.heading {
  @apply mb-2 font-sans font-medium;
}

.button {
  @apply px-6 py-2.5 font-serif text-lg font-bold;
}
